import image2 from '../images/image2.jpg';
import image3 from '../images/image3.jpg';
import image4 from '../images/Sustainable_Development_Goals.jpg';
import image5 from '../images/noi.jpg'
import image6 from '../images/image9.jpg'
import './card.css'

function Card(props) {
  var text;
  var idCard;
  var srcImg;

  switch (props.title) {
    case 'CHE COS’E LA CSR?':
      text = "La responsabilità sociale delle imprese (RSI) o, Corporate Social Responsibility (CSR), così come definito dall’Unione Europea, è la manifestazione da parte di piccole, medie e grandi imprese, di fronteggiare e gestire efficacemente, e su base volontaria, gli aspetti sociali, etici ed ambientali che impattano il loro interno, i loro rapporti commerciali e le comunità in cui svolgono le attività imprenditoriali. Essere socialmente responsabili, per le aziende, significa fare proprie tutte le preoccupazioni sociali ed ambientali che potrebbero essere impattate dalle operazioni commerciali, e dunque significa non solo ottemperare a degli obblighi, ma “lanciare il cuore oltre l’ostacolo” investendo di più sul capitale umano, sull’ambiente e sui rapporti con le altre parti interessate. L'attuazione coerente della RSI (CSR) è un contributo essenziale allo sviluppo sostenibile e al superamento delle sfide sociali, ed influisce positivamente e massicciamente sulla competitività stessa delle aziende.";
      idCard = "che_cos_e_la_csr";
      srcImg = image2;
      break;
    case 'L’AGENDA 2030':
      text = "E un programma d’azione per le persone, il pianeta e la prosperità sottoscritto nel 2015 dai governi dei 193 Paesi membri dell’ONU in cui sono delineati 17 Obiettivi per lo Sviluppo Sostenibile che devono essere raggiunti da tutti i Paesi del mondo entro il 2030.";
      idCard = "l_agenda_2030";
      srcImg = image4;
      break;
    case 'PERCHÉ CON NOI':
      text = "Perché siamo un gruppo specializzato a livello internazionale con una esperienza ultradecennale in tema di sostenibilità che, con uno sguardo al presente ed uno al futuro digitale, ha creato una proposta agile ed innovativa capace di guidare qualsiasi azienda nel proprio cammino verso la Sostenibilità, aiutandola inoltre a far conoscere ai propri stakeholder i propri impatti ambientali e sociali considerati come un valore aggiunto alla strategia aziendale.";
      idCard = "perche_con_noi";
      srcImg = image5;
      break;
    case 'SERVIZI':
      idCard = "servizi";
      srcImg = image6;
      break;
    default:
      break;
  }

  if (props.title === "SERVIZI") {
    return (
      <div className="infoCard" id={idCard}>
        <div className='cardTitleServiziPhoto' style={{ backgroundImage: 'url(' + `${srcImg}` + ')' }}>
          <h4 className='cardTitle'>{props.title}</h4>
          <div className='servicesContainer'>
            <a className='service' href='#matrice_di_materialita'>Analisi di materialità</a>
            <a className='service' href='#stakeholder_engagement'>Stakeholder engagement</a>
            <a className='service' href='#bilancio_personalizato'>Bilancio semplificato di sostenibilità personalizato</a>
            <a className='service' href='#bilancio_custom'>Bilancio di sostenibilità automatico per micro, piccola e media impresa</a>
            <a className='service' href='#rating'>Rating di sostenibilità della tua azienda</a>
            <a className='service' href='#misura'>Misura del contributo dell’azienda agli obiettivi di sviluppo sostenibile</a>
            <a className='service' href='#diagnosi'>Diagnosi e piano d’azione di genere</a>
          </div>
        </div>

      </div>
    );
  } else {
    return (
      <div className="infoCard" id={idCard}>
        <div className='cardTitlePhoto' style={{ backgroundImage: 'url(' + `${srcImg}` + ')' }}>
          <h4 className='cardTitle'>{props.title}</h4>
        </div>
        <p className='cardText'>{text}</p>
      </div>
    );
  }
}

export default Card;