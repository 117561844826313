/* eslint-disable default-case */
import image4 from '../images/image4.png';
import image6 from '../images/image6.jpg';
import image7 from '../images/image7.jpg';
import image8 from '../images/estudioSustentabilidad.jpg'
import image9 from '../images/puntaje-sustentabilidad.jpg'
import image10 from '../images/ecologyBlocks.jpg'
import image11 from '../images/equality.jpg'
import image12 from '../images/codiceetico.jpg'

import './informationCard.css'

function InformationCard(props) {
  var title1, title2;
  var idInformationCard;

  switch (props.title) {
    case 'MATRICE DI MATERIALITÀ':
      title1 = "Cos’è la matrice di materialità";
      title2 = "Quali sono le linee guida da seguire per sviluppare la matrice di materialità?";
      idInformationCard = "matrice_di_materialita";
      break;
    case 'STAKEHOLDER ENGAGEMENT':
      title1 = "Cos’è lo stakeholder engagement?";
      title2 = "Come avviene un processo di stakeholder engagement?";
      idInformationCard = "stakeholder_engagement";
      break;
    case 'BILANCIO SEMPLIFICATO DI SOSTENIBILITÀ PERSONALIZZATO':
      title1 = "L’importanza di redigere un bilancio di sostenibilità";
      title2 = "Qual è il valore del bilancio di sostenibilità?";
      idInformationCard = "bilancio_personalizato";
      break;
    case 'BILANCIO DI SOSTENIBILITÀ AUTOMATICO PER MICRO, PICCOLE E MEDIE IMPRESE':
      title1 = "Alcune considerazioni";
      idInformationCard = "bilancio_custom";
      break;
    case 'RATING DI SOSTENIBILITÀ DELLA TUA AZIENDA':
      idInformationCard = "rating";
      break;
    case 'MISURA DEL CONTRIBUTO DELL’AZIENDA AGLI OBIETTIVI DI SVILUPPO SOSTENIBILE':
      title1 = "Vantaggi";
      idInformationCard = "misura";
      break;
    case 'DIAGNOSI E PIANO D’AZIONE DI GENERE':
      idInformationCard = "diagnosi";
      break;
    case 'CODICE ETICO':
      title1 = "Qual è il valore del codice etico aziendale?";
      idInformationCard = "codice_etico";
      break;
  }

  if (props.title === "MATRICE DI MATERIALITÀ") {
    return (
      <div className="informationCard" id={idInformationCard}>
        <h2 className="serviceTitle">MATRICE DI MATERIALITÀ</h2>
        <div className='serviceData'>
          <img className='serviceImg' src={image4}></img>
          <div className='serviceDescription'>
            <h4 className='subTitle'>{title1}</h4>
            <p>La matrice di materialità è una delicata analisi che permette alle imprese di identificare quali sono quegli aspetti ambientali, sociali ed economici che potrebbero avere impatti sia sulla propria attività, che sugli stakeholder di riferimento. Questa analisi favorisce l’identificazione degli argomenti prioritari, che andranno necessariamente trattati nell’ambito di un report.</p>
            <p>La nostra esperienza nel settore della sostenibilità, ci permette di guidare con efficacia le imprese interessate a cogliere le opportunità che nascono attraverso l’elaborazione di una accurata matrice di materialità.</p>
            <h4 className='subTitle'>{title2}</h4>
            <p>Ad oggi, le linee guida maggiormente utilizzate sono i GRI Standards, principale riferimento internazionale per i processi di sustainability reporting (qui disponibili i GRI Standards in italiano). La definizione di materialità offerta da GRI Standards è molto chiara: nel bilancio di sostenibilità, la materialità è quel principio che determina quali temi rilevanti sono sufficientemente importanti da essere oggetto di rendicontazione.</p>
            <p>Secondo i GRI Standards, un bilancio di sostenibilità dovrebbe rendicontare gli aspetti materiali, ovvero quelli che:</p>
            <ul>
              <li>Riflettono gli impatti ambientali, sociali ed economici di una organizzazione.</li>
              <li>Influenzano sostanzialmente le valutazioni e le decisioni degli stakeholders.</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  if (props.title === "STAKEHOLDER ENGAGEMENT") {
    return (
      <div className="informationCard" id={idInformationCard}>
        <h2 className="serviceTitle">STAKEHOLDER ENGAGEMENT</h2>
        <div className='serviceData'>
          <div className='serviceDescription'>
            <h4 className='subTitle'>{title1}</h4>
            <p>Gli stakeholder sono definiti come soggetti o individui che possono essere influenzati in modo significativo dalle attività̀, dai prodotti o dai servizi di una organizzazione. Sono stakeholder, anche coloro le cui azioni possono incidere sulla capacità dell’impresa stessa di attuare le proprie strategie o raggiungere i propri obiettivi.</p>
            <p>In pratica gli stakeholder possono essere soggetti interni (dipendenti, rappresentanti sindacali, soci e investitori) o esterni (banche ed istituti di credito, partner, clienti, Enti Locali, autorità,  istituzioni, fornitori e appaltatori, comunità locali, ONG, sigle sindacali e associazioni di categoria), che risentono – in modo diretto o indiretto – delle scelte strategiche intraprese da una organizzazione, e/o sono eventualmente in grado di influenzare le scelte strategiche intraprese dalle aziende.</p>
            <p>Per stakeholder engagement si intende il processo sistematico di coinvolgimento di tali interlocutori rispetto alle tematiche ritenute maggiormente rilevanti – cosiddette “materiali” – sia per l’impresa che per loro stessi.</p>
            <h4 className='subTitle'>{title2}</h4>
            <p>Un processo di stakeholder engagement si articola attraverso le seguenti fasi:</p>
            <ul>
              <li>Profilazione e mappatura degli stakeholder, da sottoporre a revisione e aggiornamento periodici.</li>
              <li>Determinazione, sulla base della mappatura degli stakeholders, di tutti quei canali attraverso cui avviene l'avvicinamento di questi alle imprese, con quale regolarità e qual è il loro grado di conoscenza in merito alla strategia dell'azienda.</li>
              <li>Elaborazione dei piani di engagement che includano gli stakeholder identificati.</li>
            </ul>
          </div>
          <img className='serviceImg' src={image6}></img>
        </div>
      </div>
    );
  }
  if (props.title === "BILANCIO SEMPLIFICATO DI SOSTENIBILITÀ PERSONALIZZATO") {
    return (
      <div className="informationCard" id={idInformationCard}>
        <h2 className="serviceTitle">BILANCIO SEMPLIFICATO DI SOSTENIBILITÀ PERSONALIZZATO</h2>
        <div className='serviceData'>
          <img className='serviceImg' src={image7}></img>
          <div className='serviceDescription'>
            <h4 className='subTitle'>{title1}</h4>
            <p>Il bilancio di sostenibilità è quel documento non finanziario che raccoglie le buone pratiche messe in atto da un’azienda, che le permette di integrare le informazioni relative all’impatto sociale e ambientale della sua attività, al proprio documento di rendicontazione finanziaria e/o Bilancio Sociale.</p>
            <p>Le imprese hanno così l’opportunità di analizzare e comunicare l’esito del loro impegno ai propri stakeholder (azionisti, clienti, fornitori, dipendenti, comunità, enti finanziatori, pubblica amministrazione, associazioni del territorio, media, ecc.).</p>
            <p>Il bilancio di sostenibilità è un documento che rappresenta non solo un potente strumento di comunicazione, ma anche uno strumento utile alla pianificazione in quanto, grazie ad un’analisi costante ed aggiornata, consente di verificare i miglioramenti ottenuti e di programmare obiettivi, anche ambiziosi, per il futuro, promuovendo così l’impegno dell’azienda per uno sviluppo economico duraturo.</p>
            <h4 className='subTitle'>{title2}</h4>
            <ol>
              <li>Comunica il valore generato dall’impresa a livello sociale ed ambientale, ma anche sul piano etico e della trasparenza, dimostrando ai partners, e a tutti gli stakeholders, la propria affidabilità a 360°.</li>
              <li>Consente di integrare e sviluppare le buone pratiche, verificando e migliorando i propri parametri in termini di sostenibilità e di responsabilità sociale.</li>
              <li>Consolida ed accresce l’immagine e la reputazione dell’impresa che intraprende la strada della transizione GREEN.</li>
              <li>Permette di partecipare in maniera agevolata a quei concorsi pubblici che, sempre di più, riconoscono una premialità alle imprese socialmente responsabili.</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
  if (props.title === "BILANCIO DI SOSTENIBILITÀ AUTOMATICO PER MICRO, PICCOLE E MEDIE IMPRESE") {
    return (
      <div className="informationCard" id={idInformationCard}>
        <h2 className="serviceTitle">BILANCIO DI SOSTENIBILITÀ AUTOMATICO PER MICRO, PICCOLE E MEDIE IMPRESE</h2>
        <div className='serviceData'>
          <div className='serviceDescription'>
            <h4 className='subTitle'>{title1}</h4>
            <p>Le PMI hanno iniziato a prestare maggiore attenzione alla responsabilità sociale come punto di partenza per una nuova strategia di sostenibilità, mediante l'applicazione degli obiettivi di sviluppo sostenibile e delle linee guida GRI, e la successiva comunicazione dei loro progressi attraverso il Bilancio di sostenibilità.</p>
            <p>Ciò avviene in virtù del fatto che le MPMI si annoverano tra i principali stakeholders di altre imprese che, a loro volta, sono esse stesse soggette a requisiti di rendicontazione di sostenibilità sempre più esigenti. E’ facile dunque intuire che è inevitabile che tutto questo si riverserà sulle MPMI, che dovranno quindi fornire informazioni sulla sostenibilità al fine di mantenere l'accesso al finanziamento e ad altri investimenti.</p>
            <p>Il Bilancio di Sostenibilità rappresenta quindi un fattore chiave per stabilire e mantenere relazioni di fiducia tra tutte le principali parti interessate; anche nell'ambito di un sistema volontario, infatti, ci sarà l'aspettativa, da parte degli stakeholder, che le MPMI preparino e condividano le necessarie informazioni standardizzate, e infine ne progettino la divulgazione.</p>
            <p>Su questa base, la possibilità per una micro o piccola impresa di sviluppare un dipartimento di responsabilità sociale, o di svolgere un lungo processo finalizzato alla redazione del Bilancio di Sostenibilità è indubbiamente complessa, ed è per questo che abbiamo creato un sistema automatico capace di rendere questo processo completamente automatico, attraverso la sola raccolta dei dati ed un clic.</p>
          </div>
          <img className='serviceImg' src={image8} ></img>
        </div>
      </div>
    );
  }
  if (props.title === "RATING DI SOSTENIBILITÀ DELLA TUA AZIENDA") {
    return (
      <div className="informationCard" id={idInformationCard}>
        <h2 className="serviceTitle">RATING DI SOSTENIBILITÀ DELLA TUA AZIENDA</h2>
        <div className='serviceData'>
          <img className='serviceImg' src={image9}></img>
          <div className='serviceDescription'>
            <p>Un rating di sostenibilità è ciò che misura il rendimento di un’azienda in termini di responsabilità ambientale e sociale. I fornitori di rating valutano le aziende in base a una serie di criteri, tra cui il cambiamento climatico, il rispetto dei diritti umani, le relazioni con i dipendenti, la gestione della catena di fornitura e l’impegno della comunità.</p>
            <p>Le valutazioni di sostenibilità aiutano le aziende nel miglioramento delle proprie prestazioni, evidenziando quelle aree che necessitano di maggiore attenzione e miglioramento. I Rating vengono spesso utilizzati per identificare opportunità di iniziative finalizzate all’aumento dei ricavi ed alla riduzione dei costi.</p>
            <p>Per darti modo di avere una idea più chiara di questo, ti invitiamo ad effettuare questa valutazione per poter conoscere il grado di maturità della tua azienda in termini di sostenibilità.</p>
          </div>
        </div>
      </div>
    );
  }
  if (props.title === "MISURA DEL CONTRIBUTO DELL’AZIENDA AGLI OBIETTIVI DI SVILUPPO SOSTENIBILE") {
    return (
      <div className="informationCard" id={idInformationCard}>
        <h2 className="serviceTitle">MISURA DEL CONTRIBUTO DELL’AZIENDA AGLI OBIETTIVI DI SVILUPPO SOSTENIBILE</h2>
        <div className='serviceData'>
          <div className='serviceDescription'>
            <p>Nel 2015 la comunità internazionale (di cui fa parte anche la Svizzera) ha adottato l'Agenda 2030 per uno Sviluppo Sostenibile: un'assunzione di impegno per lo sviluppo dell'economia globale, per la promozione del benessere dell'umanità e della protezione dell'ambiente.</p>
            <p>L'Agenda 2030 propone un nuovo quadro di riferimento globale con 17 obiettivi per uno sviluppo sostenibile (SDGs o OSS) e 169 sotto-obiettivi ad essi associati, al cui raggiungimento sono obbligati a contribuire tutti i Paesi, ciascuno in base alle proprie possibilità.</p>
            <p>Abbiamo preparato questo Modulo Diagnostico per scoprire quali delle azioni intraprese dalla tua azienda sono in linea con gli Obiettivi di Sviluppo Sostenibile.</p>
            <h4 className='subTitle'>{title1}</h4>
            <ul>
              <li>Gestire i rischi</li>
              <li>Visibilità</li>
              <li>Nuove Opportunità</li>
              <li>Contributo allo sviluppo</li>
            </ul>
          </div>
          <img className='serviceImg' src={image10}></img>
        </div>
      </div>
    );
  }
  if (props.title === "DIAGNOSI E PIANO D’AZIONE DI GENERE") {
    return (
      <div className="informationCard" id={idInformationCard}>
        <h2 className="serviceTitle">DIAGNOSI E PIANO D’AZIONE DI GENERE</h2>
        <div className='serviceData'>
          <img className='serviceImg' src={image11}></img>
          <div className='serviceDescription'>
            <p>Le disparità di genere costituiscono uno dei maggiori ostacoli allo sviluppo sostenibile, alla crescita economica ed alla lotta alla povertà, per questo abbiamo preparato questo Modulo Diagnostico per determinare quali delle azioni intraprese dalla tua azienda sono in linea con gli Obiettivi di Sviluppo Sostenibile.</p>
            <p>Per garantire l’uguaglianza all’interno dell’azienda è necessario considerare il principio di equità come fattore fondamentale per una giusta e corretta esecuzione di tutti i processi decisionali ed operativi. La volontà di integrare la dimensione di genere e la Diversity all’interno delle politiche, delle strategie e della gestione aziendale, diventa esplicita nel momento in cui viene effettuata una diagnosi, e viene elaborato un piano d’azione operativo da sviluppare.</p>
          </div>
        </div>
      </div>
    );
  }
  if (props.title === "CODICE ETICO") {
    return (
      <div className="informationCard" id={idInformationCard}>
        <h2 className="serviceTitle">CODICE ETICO</h2>
        <div className='serviceData'>
          <div className='serviceDescription'>
            <p>Il Codice Etico esplicita quell’insieme di diritti, doveri e responsabilità che ogni collaboratrice o collaboratore aziendale, siano esse figure amministrative, dirigenti, quadri, dipendenti o partner esterni, esercita, durante lo svolgimento delle proprie mansioni, nei confronti di tutti i portatori di interesse (o stakeholder) dell’impresa stessa.</p>
            <h4 className='subTitle'>{title1}</h4>
            <p>Il primo strumento che permette all’impresa di formalizzare e comunicare la CSR all’interno e all’esterno dell’organizzazione stessa è proprio il codice etico.</p>
            <p>È un documento in cui vengono definiti i valori su cui si basa l’attività svolta dall’impresa e le responsabilità che quest’ultima si assume nei confronti dei diversi stakeholder.</p>
            <p>L’adozione di un codice etico permette all’impresa di diffondere le regole di condotta e i doveri morali che ogni membro è tenuto a rispettare, al fine di prevenire comportamenti illeciti da parte di tutti i soggetti che operano in nome e per conto dell’organizzazione.</p>
          </div>
          <img className='serviceImg' src={image12}></img>
        </div>
      </div>
    );
  }
}

export default InformationCard;