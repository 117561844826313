import React from 'react';
import ReactDOM from 'react-dom/client';

// Components
import Header from './components/header';
import Company from './components/company';
import Card from './components/card';
import InformationCard from './components/informationCard';
import Footer from './components/footer';
import ContactForm from './components/form';

// Styles
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />

    {/* Company */}
    <Company />

    {/* Cards */}

    <p className='phrase'>SERVE UN DECISO CAMBIO DI MARCIA, E NON SOLO IN CHIAVE AMBIENTALE, SOCIALE E CULTURALE, MA SOPRATTUTTO IN CHIAVE ECONOMICA. ESSERE SOSTENIBILI CONVIENE.</p>
    <br></br><br></br><br></br>
    <Card title="CHE COS’E LA CSR?" />
    <Card title="L’AGENDA 2030" />
    <Card title="PERCHÉ CON NOI" />
    <Card title="SERVIZI" />
    <br></br>

    {/* Information Cards */}
    <InformationCard title="MATRICE DI MATERIALITÀ" />
    <br></br>
    <div className='serviceDivider'></div>
    <br></br>
    <InformationCard title="STAKEHOLDER ENGAGEMENT" />
    <br></br>
    <div className='serviceDivider'></div>
    <br></br>
    <InformationCard title="BILANCIO SEMPLIFICATO DI SOSTENIBILITÀ PERSONALIZZATO" />
    <br></br>
    <div className='serviceDivider'></div>
    <br></br>
    <InformationCard title="BILANCIO DI SOSTENIBILITÀ AUTOMATICO PER MICRO, PICCOLE E MEDIE IMPRESE" />
    <br></br>
    <div className='serviceDivider'></div>
    <br></br>
    <InformationCard title="RATING DI SOSTENIBILITÀ DELLA TUA AZIENDA" />
    <br></br>
    <div className='serviceDivider'></div>
    <br></br>
    <InformationCard title="MISURA DEL CONTRIBUTO DELL’AZIENDA AGLI OBIETTIVI DI SVILUPPO SOSTENIBILE" />
    <br></br>
    <div className='serviceDivider'></div>
    <br></br>
    <InformationCard title="DIAGNOSI E PIANO D’AZIONE DI GENERE" />
    <br></br>
    <div className='serviceDivider'></div>
    <br></br>
    <InformationCard title="CODICE ETICO" />
    <br></br>
    <div className='formBackGround'>
      <br></br>
      <br></br>
      <h2 id='contatti' className='formTitle'>MODULO DI CONTATTO</h2>
      <br></br>
      {/* Form */}
      <ContactForm />
      <br></br>
    </div>

    <Footer />
  </React.StrictMode>
);
