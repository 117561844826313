import image from '../images/image1 - copia.jpg';
import './company.css';

function Company() {
  return (
    <div className='containter company' id="inizio">
      <h2>GRINSID</h2>
      <h4>Il seme della tua crescita sostenibile</h4>
      <div id='divCompanyBoxes'>
        <div className='companyBox'><p className='boxText'>Comunica il tuo impegno nella CSR a tutti i tuoi stakeholed</p></div>
        <div className='companyBox'><p className='boxText'>Crea nuove opportunitá di mercato per la tua azienda attraverso una strategia di crescita sostenibile</p></div>
        <div className='companyBox'><p className='boxText'>Migliora la reputazione del tuo marchio</p></div>
        <div className='companyBox'><p className='boxText'>Compila il tuo Bilancio di sostenibilitá</p></div>
      </div>
      <br></br><br></br>
      <img className='imgBanner' src={image} alt='Immagine dell´azienda'></img>
      <p id='companyText'>
        Una delle più grandi ed importanti sfide cui noi tutti siamo chiamati ad affrontare oggi, è senza dubbio quella di avere un’economia sostenibile. “Sviluppo eco-sostenibile” è infatti diventata la password che permette di accedere alle porte del futuro: valutazioni in termini di impatto ambientale, di salute, inclusione e trattamento dei lavoratori e, più in generale, dell’inclinazione sociale ed etica di un’azienda, sono diventate discriminanti fondamentali per la loro <b>permanenza sul mercato</b>, che siano esse grandi aziende, PMI o micro imprese. In tal senso, l’espressione “permanenza sul mercato” è intesa sia in termini di attrattività degli investitori, compresi istituti di credito e banche, sia in termini di scelta di acquisto da parte di consumatori (sempre più consapevoli e attenti all’impatto delle loro scelte di consumo) e di altri attori economici, e non economici, facenti parte della supply chain di qualunque azienda. 
        <p></p>
        Non essere sostenibili equivarrà davvero dunque a restare fuori dai giochi!
        <p></p>
        Alla luce di tutto questo, gli attori economici ed istituzionali hanno la capacità OGGI di fare la differenza, essendo infatti chiamati ad interpretare lo spirito dei tempi ed a fare la loro parte affinchè vi sia un cambiamento di paradigma culturale ed un superamento di quei modelli economici in uso ma prospetticamente rovinosi.
        <p></p>
        Grazie agli strumenti che GRINSID mette a disposizione attraverso questa piattaforma, è possibile, per qualunque tipo di azienda/Ente, intraprendere il proprio viaggio verso l’ecosostenibilità e fare la propria parte per permettere un importante sviluppo sociale ed economico strutturale. 
        <p></p>
        GRINSID affianca e guida i propri clienti nella pianificazione ed attuazione di una strategia ecosostenibile studiata ad hoc, preferibilmente su base triennale, ed effettua insieme ad ognuno di essi uno screening periodico per determinare la bontà delle scelte strategiche adottate e la crescita dell’azienda/Ente che ne deriva. 
        <p></p>
        Il risultato sarà la redazione del Bilancio di Sostenibilità semplificato annuale certificato GRI, attraverso il quale comunicare a tutti gli stakeholders il proprio impegno concreto, anche attraverso il rilancio dell’immagine del brand.  
      </p>
    </div>
  );
}

export default Company;