import './form.css';
import { useState } from 'react';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import notEmpty from '../helpers/helper';
import Swal from 'sweetalert2'
import validator from 'validator';


function ContactForm() {
    const [valueEmail, setValueEmail] = useState('');
    const [valueName, setValueName] = useState('');
    const [valueSurname, setValueSurname] = useState('');
    const [valueCompany, setValueCompany] = useState('');
    const [valuePhone, setValuePhone] = useState('');
    const [valueInterests, setValueInterests] = useState('MATRICE DI MATERIALITÀ');

    const [valueCheckbox1, setValueCheckbox1] = useState(false);
    const [valueCheckbox2, setValueCheckbox2] = useState(false);
    const [valueCheckbox3, setValueCheckbox3] = useState(false);
    
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        if (validator.isEmail(valueEmail) && 
            validator.isAlpha(valueName, 'it-IT', { ignore: '- ' }) && 
            validator.isAlpha(valueSurname, 'it-IT', { ignore: '- ' }) && 
            /^[a-zA-Z0-9.&-]+$/.test(valueCompany.replace(/[^a-zA-Z0-9.&-]/g, '')) && 
            (validator.isMobilePhone(valuePhone.replace(/[^\d]/g, ''), 'any', { strictMode: false }) || 
            /^(\+\d{1,3}\s?)?(\(?\d{3}\)?[\s-]?)?(\d{3}[\s-]?\d{4}|\d{1,4}(\s\d{1,4}){1,3})$/.test(valuePhone)) &&
            notEmpty(valueInterests) && valueCheckbox1 && valueCheckbox2 && valueCheckbox3){
                Swal.fire({
                    title: "Grazie mille!",
                    text: "Le informazioni sono state inviate con successo e ci metteremo in contatto con voi il prima possibile.",
                    icon: "success"
                });
                emailjs.sendForm('service_44sqzeh', 'template_s6yow5l', form.current, 'iR2YPYI2wHRVNfGxw')
                .then(() => {
                    setTimeout(function(){
                        window.location.href = '/'; 
                    }, 2000);
                }, (error) => {
                    console.error(error.text);
                });
        } else {
            Swal.fire({
                title: "Errore",
                text: "Si prega di completare i campi obbligatori per inviare il modulo e correttamente.",
                icon: "error"
            });
        }
    
      };

    return (
        <form className='contactForm' ref={form} onSubmit={sendEmail}>
            <div className='formscontrols'>
                <div className="mb-3">
                    <label htmlFor="inputCompany" className="form-label">Azienda</label>
                    <input type="text" name='company' className="form-control" id="inputCompany" onChange={e => setValueCompany(e.target.value)}></input>
                </div>
                <div className="mb-3">
                    <label htmlFor="selectInterests" className="form-label">Interesse</label>
                    <select className='form-select' name='interest' id='selectInterests' onChange={e => setValueInterests(e.target.value)}>
                        <option value="MATRICE DI MATERIALITÀ" defaultValue={true}>MATRICE DI MATERIALITÀ</option>
                        <option value="STAKEHOLDER ENGAGEMENT">STAKEHOLDER ENGAGEMENT</option>
                        <option value="BILANCIO SEMPLIFICATO DI SOSTENIBILITÀ PERSONALIZATO">BILANCIO SEMPLIFICATO DI SOSTENIBILITÀ PERSONALIZATO</option>
                        <option value="BILANCIO DI SOSTENIBILITÀ AUTOMATICO PER MICRO E PMI">BILANCIO DI SOSTENIBILITÀ AUTOMATICO PER MICRO E PMI</option>
                        <option value="RATING DI SOSTENIBILITÀ">RATING DI SOSTENIBILITÀ</option>
                        <option value="MISURA DEL CONTRIBUTO DELL’AZIENDA AGLI OSS">MISURA DEL CONTRIBUTO DELL’AZIENDA AGLI OSS</option>
                        <option value="DIAGNOSI E PIANO D’AZIONE DI GENERE">DIAGNOSI E PIANO D’AZIONE DI GENERE</option>
                        <option value="CODICE ETICO">CODICE ETICO</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="inputName" className="form-label">Nome</label>
                    <input type="text" className="form-control" id="inputName" name='name' onChange={e => setValueName(e.target.value)}></input>
                </div>
                <div className="mb-3">
                    <label htmlFor="inputSurname"  className="form-label">Cognome</label>
                    <input type="text"  name='surname' className="form-control" id="inputSurname" onChange={e => setValueSurname(e.target.value)}></input>
                </div>
                <div className="mb-3">
                    <label htmlFor="inputEmail" className="form-label">Email</label>
                    <input type="text" className="form-control" id="inputEmail" name='from_email' onChange={e => setValueEmail(e.target.value)}></input>
                    <div>
                        <p className='disclaimer'>(*) per i campi contrassegnati da * la compilazione è obbligatoria.</p>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="inputPhone" className="form-label">Telefono</label>
                    <input type="text" name='phone' className="form-control" id="inputPhone" onChange={e => setValuePhone(e.target.value)}></input>
                </div>
                
                <div className="mb-3 form-check">
                    <input type="checkbox" className="form-check-input" id="cbPrivacy" checked={valueCheckbox1} onChange={e => setValueCheckbox1(e.target.checked)}></input>
                    <label className="form-check-label" htmlFor="cbPrivacy">Ho letto l’Informativa sulla Privacy e la Privacy Policy e autorizzo il trattamento dei miei dati personali per le finalità descritte nei nostri servizi.[la mancata autorizzazione al trattamento comporta l’impossibilità di svolgere le attività richieste]</label>
                </div>
                <div className="mb-3 form-check">
                    <input type="checkbox" className="form-check-input" id="cbConsentment1" checked={valueCheckbox2} onChange={e => setValueCheckbox2(e.target.checked)}></input>
                    <label className="form-check-label" htmlFor="cbConsentment1">Presto il consenso al trattamento dei miei dati personali per le finalità descritte nei nostri servizi [il mancato consenso impedisce l’invio di documentazione tecnica e di contenuti di aggiornamento tecnico]</label>
                </div>
                <div className="mb-3 form-check">
                    <input type="checkbox" className="form-check-input" id="cbConsentment2" checked={valueCheckbox3} onChange={e => setValueCheckbox3(e.target.checked)}></input>
                    <label className="form-check-label" htmlFor="cbConsentment2">Presto il consenso al trattamento dei miei dati personali per le finalità descritte nei nostri servizi [il mancato consenso impedisce l’aggiornamento in merito ai nostri servizi, l’invito a nostri eventi gratuiti e la partecipazione a nostre iniziative a condizioni riservate]</label>
                </div>
            </div>
            <div className='buttonCenter'>
                <button className="submitButton">Inviare</button>
            </div>
        </form>
    )
}

export default ContactForm;