import nameLogo from '../images/grinsid.png'
import locationIcon from '../images/icons8-marcador-24Green.png'
import wppIcon from '../images/icons8-whatsapp-24Green.png'
import callIcon from '../images/icons8-llamada-50Green.png'
import enterpriseIcon from '../images/icons8-empresa-50Green.png'
import emailIcon from '../images/icons8-email-64Green.png'
import logoGrinsidLight from '../images/grinsidVerdeClaro.png'

import './footer.css'

function Footer() {
  return (
    <nav  id='footer'>
      <div className='footerData'>
        <div className='contactData'>
          <div className='dataIcon'><img className='icon' src={enterpriseIcon}></img><a className='contactText' href='https://maps.app.goo.gl/nqkwdxcQVLhLnyYQ7' target="_blank">Viale Leone 15/a, 80055 Portici (NA)</a></div>          
          <div className='dataIcon'><img className='icon' src={emailIcon}></img><a className='contactText' href='mailto:grinsidsostenibile@gmail.com'>grinsidsostenibile@gmail.com</a></div>          
          <div className='dataIcon'><img className='icon' src={wppIcon}></img><a className='contactText' href='https://wa.me/393475024593' target="_blank">+39 3475024593</a></div>          
          <div className='dataIcon'><img className='icon' src={callIcon}></img><a className='contactText' href='tel:+39081472807'>+39 081472807</a></div>          
          <div className='dataIcon'><img className='icon' src={locationIcon}></img><a className='contactText' href='https://maps.app.goo.gl/3Hejm3UtY8gFkFHy6' target="_blank">Corso Garibaldi 40, 80055 Portici (NA)</a></div>
        </div>
        <img src={logoGrinsidLight} className='grinsidNameLogo'></img>
      </div>
    </nav>
  );
}

export default Footer;
