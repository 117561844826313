import logoImg from '../images/grinsid.png';
import logo2Img from '../images/logoGrinsid.png'
import { useRef } from 'react';
import './header.css'
import { FaBars, FaTimes } from 'react-icons/fa'
import React, { useEffect, useState } from "react";

function Header() {
  /*navbar-expand-lg*/
  /* collapse navbar-collapse row justify-content-center */
  //change nav color when scrolling
  const [color, setColor] = useState(false)
  const changeColor = () => {
    if (window.scrollY >= 300) {
      setColor(true);
    } else {
      setColor(false);
    }
  }
  window.addEventListener('scroll', changeColor);

  /* this is for responsive navBar */
  const [show, setShowBar] = useState(false);
  const showNavBar = () => {
    if (show == false) {
      setShowBar(true);
    } else {
      setShowBar(false);
    }
  }

  return (
    <div className='backGroundImg'>
      <nav id="upNav" className={color ? "navbar bg-body-tertiary fixed-top navbar-scrolled" : "navbar bg-body-tertiary fixed-top"}>
        <div className='navFirstRow'>
          <a className="navbar-brand" href="#inizio"><img className='navLogo' src={logoImg} alt='logo'></img></a>
          <button className='navBarbtn' onClick={showNavBar}>
            <FaBars />
          </button>
        </div>
        <div id="navbarItems">
          <a className="navlink" aria-current="page" href="#inizio">Inizio</a>
          <a className="navlink" href="#che_cos_e_la_csr">Che cos'e la csr?</a>
          <a className="navlink" href="#l_agenda_2030">L'agenda 2030</a>
          <a className="navlink" href="#perche_con_noi">Perché con noi</a>
          <a className="navlink" href="#servizi">Servizi</a>
          <a className="navlink" href="#contatti">Contatti</a>
        </div>
          <div className={show ? 'mobileNav' : 'mobileNav notShow'}>
            <a className="navlink short" aria-current="page" href="#inizio">Inizio</a>
            <a className="navlink" href="#che_cos_e_la_csr">Che cos'e la csr?</a>
            <a className="navlink" href="#l_agenda_2030">L'agenda 2030</a>
            <a className="navlink" href="#perche_con_noi">Perché con noi</a>
            <a className="navlink short" href="#servizi">Servizi</a>
            <a className="navlink short" href="#contatti">Contatti</a>
          </div>
      </nav>
      <img className='logo' src={logo2Img}></img>
      <a className='contact' href="#contatti">Contatti</a>
    </div>
  )
}


export default Header;
